html {
    position: relative;
    min-height: 100%;
}

body,html {
    padding: 0;
    margin: 0;
    font-family: 'Lato';
    color: #f2ede3;
    font-size: 0.9em;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    background-image: url(../../../img/wallpaper_blue.jpg);
    background-size: 100% 100%;
    background-color: #000;
}
/* Sticky footer styles
-------------------------------------------------- */

.content {
    margin-top: 30px;
}

#background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.63);
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.33);
}
.footer p {
    padding-top: 10px;
    font-size: 11px;
}

code {
    font-size: 80%;
}

a {
    color: white;
    text-decoration: none;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}
a:hover,a:active,a:focus {
    color: skyblue !important;
    text-decoration: none;
}

h2 {
    text-align: center;
    margin: 0;
    padding: 20px;
    width: 100%;
    font-size: 1.8em;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
}
.navbar-default .navbar-brand {
    color: white;
}
.navbar-default {
    background-color: rgba(0,0,0, 0.32);
    border: none;
    background-image: none;
} 
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #e7e7e7;
}
.navbar {
    position: relative;
    min-height: 100px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a  {
    color: whitesmoke;
    background: none;
    background-image: none;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover, 
.navbar-default .navbar-nav > .open > a:focus {
    background: none;
    background-color: rgba(4, 56, 76, 0.96);
    background-image: none;
    color: whitesmoke;
}
.navbar-default .navbar-toggle {
    border-color: #0D4D64;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #619BBF;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: rgba(46, 109, 164, 0.65);
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #464141;
    margin-top: 24px;
}
.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
    margin-left: -15px;
    margin-top: -11px;
}
.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
    margin-right: 20px;
    margin-left: 0;
    margin-top: 22px;
}
.dropdown-menu > li > a {
    color: #FFF;
}
.dropdown-menu {
    border-top: 3px solid skyblue;
    width: 100%;
    color: white;
    background-color: rgba(14, 35, 42, 0.67);
}
.dropdown-menu .divider {
    background-color: rgba(255,255,255, 0.25);
}
.dropdown-header {
    color: #fff;
    font-weight: 800;
    font-size: 16px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li:hover,
.dropdown-menu > li:focus {
    background: rgba(0,0,0, 0.15);
}
h1 {
    text-shadow: 4px 3px 8px #333;
}

input {
    text-shadow: 6px 5px 8px #333;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
    width: 90%;
    font-style: italic;
}

.form-control {
    background: rgba(0,0,0,0.3);
    border: 1px solid #1B192F;
    color: whitesmoke;
}

.btn-styled {
    border-radius: 2px;
    color: #fff !important;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #212121;
    background: #373f4f;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(5%,#7a8494), color-stop(95%,#282f3d));
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7a8494', endColorstr='#282f3d',GradientType=0 );
    background: -moz-linear-gradient( top, #7a8494 5%, #282f3d 95%);
    background: -webkit-linear-gradient( top, #7a8494 5%, #282f3d 95%);
    background: -o-linear-gradient( top, #7a8494 5%, #282f3d 95%);
    background: -ms-linear-gradient( top, #7a8494 5%, #282f3d 95%);
    background: linear-gradient( to bottom, #7a8494 5%, #282f3d 95%);
}

select { -webkit-appearance: none; }
option, optgroup { -webkit-appearance: none; }

::-webkit-input-placeholder {
    text-shadow: 4px 3px 8px #333;
    color: white;
}

:-moz-placeholder { /* Firefox 18- */
    text-shadow: 4px 3px 8px #333;
    color: white;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-shadow: 4px 3px 8px #333;
    color: white;  
}

:-ms-input-placeholder {  
    text-shadow: 4px 3px 8px #333;
    color: white;  
}

@media screen and (max-width: 46.5em) {
    .bl-content, .bl-box {
        font-size: 75%;
    }
    .bl-expand .bl-box {
        height: 130px;
    }
    .bl-content > ul li {
        width: 40%;
    }
    #search-bar {
        font-size: 17px;
    }
    #error-result-block {
        font-size: 14px;
    }
}

@media (min-width: 500px) {
    #education .description {
        width: 85%;
    }
}
@media (min-width: 768px) {
    #education .description {
        width: 85%;
    }
}
@media (min-width: 992px) {
    #education .description {
        width: 87%;
    }
}
@media (min-width: 1200px) {
    #education .description {
        width: 90%;
    }
}
